.TagsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: baseline;
    height: 75rem;
    margin: 20px 0px 20px 0px;
}

.tagItem {
    background: #ccc;
    padding: 5px 10px 5px 5px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.topContainer {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.searchTags_container {
    width: 75%;
}

.searchTags_container_inputField {
    height: 90%;
    width: 70%;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
}