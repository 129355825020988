.cmr_event_management_flex_box {
    display: flex;
    padding: 10px;
}

.cmr_event_list {
    padding: 10px;
}

.cmr_members_list {
    padding: 10px;
}