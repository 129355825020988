.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* //////////////////Header\\\\\\\\\\\\\\\ */
.header {
  color: #fff;
  background-color: #284672;
  position: relative;
  height: 70px;
  padding-top: 10px;
  padding-left: 40px;
  /* padding-top: 25px; */
  box-shadow: 2px 2px 5px #888;
  z-index: 2;
}

.header_elements {
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 35%;
}

.header_elements h4 {
  width: max-content;
}

.menu-shrink {
  display: inline;
}

.bars-button {
  padding: 10px 10px;
  background-color: #344F79;
  border-radius: 8px;
  color: #C2CDD9;
  margin-right: 1em;
  cursor: pointer;
  transition: color ease-in .2s;
}

.signoutbtn {
  padding: 0px 20px;
  background-color: #344F79;
  border-radius: 8px;
  color: #C2CDD9;
  /* margin-right: 40px; */
  cursor: pointer;
  transition: color ease-in .2s;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 199px;
  height: 50px;
}

.signoutbtn {
  font-size: 12px;
}

/* Button css */


/* .side-nav.shrink {
  width: 5% !important;
}

.page-content.shrink {
  width: 95% !important;
  margin-left: 5% !important;
} */


a svg {
  vertical-align: middle;
}

a:hover {
  color: #ccc;
  text-decoration: none;
}

.signoutbtn i {
  font-size: 18px;
}

a.bars-button:hover,
a.signoutbtn:hover {
  color: #fff;
  text-decoration: none;
}

.signoutbtndiv {
  float: right;
  margin-right: 1em;
  cursor: pointer;
}

.header_user_icon {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
}

.header_profile_card {
  display: flex;
  width: 25rem;
  align-items: center;
  padding-bottom: 10px;
}

/* -----------Charts---------- */
.dgraph {
  height: 85%;
}

.graph {
  height: 200%;
  width: 100%;
}

.counttab {
  border-radius: 1rem;
  width: 22%;
  margin: 10px;
  display: inline-block;
  padding: 10px 15px;
}

.counttab i {
  border-radius: 50%;
  padding: 8px;
  text-align: center;
  color: #fff;
}

.combo1 {
  background-color: #dfffec;
  color: #006e24;
}

.combo1 i {
  background-color: #2add41;
}

.combo2 {
  background-color: #eae0fc;
  color: #37006e;
}

.combo3 {
  background-color: #fff3dd;
  color: #686300;
}

.combo4 {
  background-color: #ffe1e1;
  color: #6e0000;
}

.combo2 i {
  background-color: #b05cff;
}

.combo3 i {
  background-color: #ffca39;
}

.combo4 i {
  background-color: #ff556f;
}

.maincontent {
  margin-top: -3rem;
}

.sidecontent {
  border-radius: 2rem;
}

.feedback {
  background-color: #fff;
  margin-top: 3rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  /* overflow-y: scroll; */
}

.tabs,
.user-actions {
  background: #fff;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #ccc;
  padding: 20px;
  margin-top: 3rem;
}

.action-div {
  display: inline-block;
  padding: 5px;
  margin: 10px;
  text-align: center;
  color: #005580;
  cursor: pointer;
  width: 116px;
}

.action-div:hover i {
  background-color: #005580;
  color: #fff;
}

.action-div i {
  border-radius: 5px;
  border: 1px solid #005580;
  padding: 8px;
  font-size: 30px;
  transition: all ease .2s;
}

.action-div span {
  position: absolute;
  background-color: #d10;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  width: 22px;
  height: 22px;
  padding: 3px;
  margin-top: -88px;
  margin-left: 12px;
}

.hr {
  border-color: #4B4B4B;
  margin-right: 1%;
  margin-top: 0rem;
}

.sidecontentbox {
  width: 104%;
  height: 44rem;
  background-color: #fff;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  margin-top: 1rem;
  /* overflow-y: scroll; */
}

.image-preview img {
  max-width: 100%;
  max-height: 200px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 3.5px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffA95d;
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3e8e41;
}

.sidenav_cntr1 {
  display: none;
  /* width: 16%; */
}

#sidenav_menu_btn {
  background-color: transparent;
  border: none;
}

#sidenav_menu_btn1 {
  border: none;
  background-color: transparent;
}

.full_page {
  position: relative;
  width: 100%;
  font-size: 100%;
}

.container1 {
  height: 100vh;
  background-image: linear-gradient(90deg, #164373, #67a3ff);
}

.card_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  align-content: space-around;
  padding-top: 50px
}

.cards {
  border: 2px solid;
  width: 15%;
  text-align: center;
  height: 250px;
  margin: 10px;
  background: antiquewhite;
  box-shadow: 5px 10px #ffA95d;
  border-radius: 15px;
}

.member_cards {
  border: 2px solid;
  width: 30%;
  text-align: center;
  height: 250px;
  margin: 10px;
  background: bisque;
  box-shadow: 5px 10px #888888;
  border-radius: 35px;

}

.cards:active {
  transform: translateY(4px);
}

/* .login_det{
  background-image: linear-gradient(90deg,#164373,#67a3ff);
} */
/* .landing_container{
  height:100vh;
  padding-top: 50px;
  background-image: linear-gradient(90deg,#164373,#67a3ff);
} */

/* .margin-auto{
  margin-top: 10% ;
  margin-bottom: auto;
  margin-left: 35%;
  margin-right: auto;
  width: 30vw;
  padding: 20px;
  border-radius: 1.2rem;
  box-shadow: 2px 2px 12px #222;
  background-color: #eee;
  color: #444;
} */
#profile_circle {
  border: 2px solid;
  border-radius: 50px;
  height: 100px;
  width: 100px;
  margin: 30px auto auto auto;
}

#landing_img {
  width: 200px;

}

/* .login_form{
  display: block;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 28vw;
  padding-bottom: 50px;
  text-align: center;
  margin: auto;
  align-items: center;
} */
/* .login_input{
  padding: 8px;
    width: 24rem;
border-radius: 8px;
background: #95a8c6;
color: #0a192f;

} */

/*-----------------------------------------------
Navbar css
--------------------------------------------------*/
.side-nav {
  padding: 0;
  transition: width ease-in .2s;
  position: relative;
  z-index: 3;
  position: fixed;
}

.img-container {
  width: 240px;
  margin: auto;
  padding: 10px;
}

#navimage {
  width: 60px;
}

#userimage {
  width: inherit;
  vertical-align: middle;
}

#sidenavmax h1 {
  display: inline;
  font-size: 30px;
  margin-left: 10px;
  color: #ccc;
  vertical-align: middle;
}

.user-panel {
  background-color: #0004;
  color: #eee;
  padding: 12px;
  display: flex;
}

.userImagediv {
  width: 60px;
  height: 60px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 50%;
  transition: width ease-in .2s;
}

.user-details {
  margin-left: 10px;
  display: inline-block;
}

.user-panel h1,
h2,
h3,
h4,
h5 {
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.vertical-spacer-3 {
  height: 3rem;
}

.vertical-spacer-5 {
  height: 5rem;
}

.vertical-spacer-8 {
  height: 8rem;
}

.vertical-spacer-16 {
  height: 16rem;
}

.vertical-spacer-24 {
  height: 24rem;
}

.float-none {
  float: none !important;
}

.notifications {
  position: absolute;
  transform: translate3d(-139px, 17px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

/* .brand-container{
  position: absolute;
  margin-top: -144px;
  background: #eee;
  border-radius: 50%;
  padding: 22px;
  margin-left: 177px;
} */
#navbar_signutbtn {
  color: #FFF;
  margin-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  border: 0.5px solid #ffffff75;
  background-size: 200% auto;
  background-image: linear-gradient(to right, rgb(18, 150, 244) 0%, #3ce 10%, rgb(20, 129, 193) 100%);
}

#navbar_signutbtn1 {
  color: #FFF;
  text-align: center;
  text-transform: uppercase;
  border: none;
  /* border: 0.5px solid #ffffff75; */
  background-color: transparent;
}

#navbar_signutbtn1:hover {
  background-position: right center;
}

.dropdown-content {
  background: #000;
}

/*-----------------------------------------------
Page content css
--------------------------------------------------*/
.page-content {
  height: 100vh;
  padding: 0;
  margin-left: 16.6667%;
  /* transition: all ease-in .2s; */
  /* margin-left: -13.6vw; */
  /* width: 100%; */
  /* margin-top: -20px; */
}

.container2 {
  overflow: scroll;
  background-color: #fff;
  color: #444;
}

.content {
  /* text-align: left; */
  height: 100%;
  color: #444;
  padding: 30px;
}

/* #login_lable{
  display: block;
  margin-right: 10px;
  font-size: 18px;
} */
hr {
  border-color: #444;
}

/*-----------------------------------------------
Table css
--------------------------------------------------*/
#edit_relation_lkp_btn {
  background: #b0d2eb;
  border: none;
  border-radius: 5px;
  padding: 10px;
}

table th {
  background-color: #026fbf50;
  border: 1px solid #eee;
}

table td {
  background-color: #ddd;
  border: 1px solid #eee;
}

table th,
table td {
  padding: 1rem;
}

.category_image {
  width: 90px;
}

.add_member_btn {
  background: #b0d2eb;
  border: none;
  border-radius: 5px;
  padding: 10px;
}

#edit_issue_btn {
  border: none;
  background: #b0d2eb;
  border-radius: 5px;
  padding: 5px;
}

#add_response_btn {
  background: #b0d2eb;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.btn {
  border-radius: 5px;
  background: #467dff;
  padding: 10px 50px;
  cursor: pointer;
  margin: 10px;
  transition: 0.5s;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 10px #aaa;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

#update_product_lkp_btn {
  border: none;
  border-radius: 5px;
  padding: 10px;
  background: #46cef6;
}

#delete_product_lkp_btn,
#delete_product_btn {
  float: right;
  background: #ff4b4b;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
}

#toggleedit_products_lkp_close_btn,
#toggleedit_products_close_btn {
  padding: 10px 10px !important;
}

#edit_product_notes {
  border: none;
  background: #afd2eb;
  border-radius: 5px;
  padding: 5px;
}

.btn2 {
  color: #eee;
  z-index: 1;
}

.btn2:hover {
  color: #222;
}

.btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #3ce;
  z-index: -1;
  transition: 0.5s;
}

.btn2::before {
  top: 0;
  border-radius: 0 0 50% 50%;
}

.btn2:hover::before {
  height: 180%;
}

/* #accept_btn{

}
#reject_btn{

}
#approval_col{
  
} */



/* //////////////////// Data Tables //////////////////// */
.MuiBox-root {
  height: 50rem;
  margin-left: auto;
  background: #ffd4ae;
  padding: 10px 10px 0px 230px;
}

.MuiTableCell-root,
.MuiToolbar-root {
  background: #ffd4ae;
}

/* #root{
  height:100%;
  background-color: #ffD4AE;
} */
.css-a35cbc {
  width: auto !important;
}

.users_Table {
  width: 80%;
  margin: auto;
  height: auto;
}

.data_table {
  display: inline-table;
  margin: 100px;
}

.table_heading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
  align-items: center;
}

/* ////////////////// Invitations Page //////////////////// */
#inv_gif {
  width: 100%;
  height: 40%;
  padding-top: 60px;
}

/* ---------------- relation names table---------------- */
#relationNames {
  width: 40%;
  margin-left: 30%;
}

#relationNames.td {
  padding: 10px;
}

/* ---------------- add member form ----------------- */

.addmemform {
  display: none;
  width: 40%;
  height: 30%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 4px #888484;
  margin-left: 30%;
  background: cadetblue;
  padding: 50px 20px;
  flex-direction: column;
}

/* ---------------- add relation form --------------- */
.addrelform {
  display: none;
  width: 40%;
  height: 30%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 4px #888484;
  margin-left: 30%;
  background: cadetblue;
  padding: 50px 20px;
  flex-direction: column;
}

#showform {
  float: right;
}

.add_rel_form {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px;
  overflow: scroll;
}

.update_user_form {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0px 10px;
  overflow: scroll;
}

#addrel {
  float: inline-start;
}

#close_rel_form {
  float: inline-end;
}

#form_btn_section {
  padding-top: 10%;
}

#showform {
  border: 1px solid black;
  border-radius: 4px;
  padding: 9px 9px;
  color: #000;
  background: rgb(76, 141, 155);
  text-align: center;
}

#addrel,
#close_rel_form {
  border: 1px solid black;
  border-radius: 4px;
  padding: 9px 9px;
  color: #fff;
  background: rgb(76, 141, 155);
  text-align: center;
}

#showform:hover {
  background-color: #005e75;
  /* Green */
  color: white;
}

/*--------------------------------------------
Modal and input css
--------------------------------------------*/
.fade {
  opacity: 1 !important;
}

.modal-backdrop {
  background-color: #0008 !important;
  height: 100%;
}

.modal-dialog {
  margin: 10rem auto;
}

.modal.fade .modal-dialog {
  transform: translate(0, 0)
}

.modal-mobile {
  width: 476px;
  height: 667px;
  border: 10px solid #444;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.react-datepicker {
  font-size: 16px;
  background: #cecece;
}

.react-datepicker__header {
  background-color: #007BFF;
  /* Header background color */
  color: #fff;
  /* Header text color */
}

.react-datepicker__day {
  font-size: 14px;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
  cursor: pointer;
  background-color: transparent;
  /* Add a transparent background */
  border: none;
  /* Remove borders */
}

.react-datepicker__day-names {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.react-datepicker__day-name {
  font-size: 12px;
  width: 2rem;
  text-align: center;
}

.react-datepicker__week {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Ensure proper alignment with weeks */
}

.react-datepicker__current-month {
  font-size: 18px;
}

.react-datepicker__navigation--next {
  border-left-color: #007BFF;
  /* Next month arrow color */
}

.react-datepicker__navigation--previous {
  border-right-color: #007BFF;
  /* Previous month arrow color */
}

.react-datepicker__day--selected {
  background-color: #007BFF;
  color: #fff;
}

.dob-field {
  margin-bottom: 200px;
  display: inline-flex;
}

.modal-mobile .modal-body {
  padding: 0;
}

#rel_lkp_update_btn {
  float: right;
}

#delete_member_btn {
  float: left;
  background: orangered;
}

#delete_member_btn:hover {
  background: red;
}

.preview_back {
  width: 456px;
  height: 647px;
  border-radius: 8px;

}

.special-input {
  padding: 10px 10px;
  width: 100%;
  font-size: 14px;
}

.special-lable {
  position: absolute;
  font-size: 20px;
  background-color: #fff;
  margin: 7px 0 0 14px;
  left: 14px;
  transition: .2s;
}

.special-input.hastext+.special-lable,
.special-input:focus+.special-lable {
  font-size: 12px;
  margin: -7px 0 0 8px;
  padding: 0 8px;
}

.special-input:focus+#special-lable_title {
  background: #cecece;
}

.special-input:focus+#special-lable_description {
  background: #cecece;
}

.special-input:focus+#special-lable_price {
  background: #cecece;
}

.special-input:focus+#special-lable_discount {
  background: #cecece;
}

.special-input:focus {
  border: 1px solid #467dff;

}

#special-lable_title {
  background: #fff;
}

#special-lable_description {
  background: #fff;
}

#special-lable_price {
  background: #fff;
}

#special-lable_discount {
  background: #fff;
}

.form-field {
  margin-bottom: 20px
}

.image-input {
  display: none !important
}

.image-lable {
  background-color: #467dff;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 16px;
  color: #fff;
}

.uploaded-image-preview {
  height: 90px;
  width: 50px;
  margin-left: 50px;
}

.modal-content {
  border-radius: 10px;
}

.modal-header .btn-close,
.modal-body .btn-close {
  position: absolute;
  right: -8px;
  content: 'x';
  top: -11px;
  padding: 4px 10px;
  border-radius: 50%;
  background-color: #ffaeae;
  box-shadow: none;
  border: none;
  color: #ae2929;
  font-size: 20px;
  z-index: 100;
}



#btn-close_id {
  position: absolute;
  right: -8px;
  content: 'x';
  padding: 4px 10px;
  border-radius: 50%;
  background-color: #ffaeae;
  box-shadow: none;
  border: none;
  color: #ae2929;
  font-size: 20px;
  z-index: 100;
}

.validation-alert {
  color: #ae2929;
  display: none;
}

.validation-alert.invalid {
  display: block;
}

/* Tool Tip CSS */
/* Tooltip container style */
.tooltip-container {
  position: relative;
  cursor: pointer;
}

/* Tooltip text style */
.tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -30px;
  /* Position the tooltip above the element */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
}

/* Tooltip container hover style */
.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------
Loader css
---------------------------------------------*/
.loader-back {
  background-image: linear-gradient(90deg, #164373, #67a3ff);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

.loader-element {
  position: relative;
  top: 40%;
  left: 48%;
}

.loader-svg {
  position: absolute;
  height: 80px;
  top: 38px;
  left: 30px;
}

/*------------------------------------------------
Invitations css
-------------------------------------------------*/
.float-none {
  float: none;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.main-content {
  height: 80vh;
  /* overflow: scroll; */
}

.vertical-border {
  width: 1px;
  border-right: 1px solid #aaa;
}

.button-to-right {
  float: right;
  position: relative;
  margin-top: -64px;
  right: 30px;
}

.invitation_card {
  background-color: #ddd;
  padding: 15px 10px 10px 10px;
  border-radius: 5px;
  margin: 20px;
  box-shadow: 61.667px 16.667px 0px rgba(0, 0, 0, 0.00),
    0px 40px 16.667px 0px rgba(0, 0, 0, 0.01),
    0px 21.667px 13.333px 0px rgba(0, 0, 0, 0.05),
    0px 10px 10px 0px rgba(0, 0, 0, 0.09),
    0px 1.667px 5px 0px rgba(0, 0, 0, 0.10);
  transition: .5s;
  max-height: 31rem;
  width: 150px;
  height: 207px
}

.invitation_card_bg_container {
  height: 182px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.invitation_card_title {
  color: #284672;
}

.invitation_card_footer {
  width: 130px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.invitation_card_download_count {
  color: #284672;
}

.invitation_card_download_count h5 {
  padding: 2px;
  display: flex;
  justify-content: center;
}

.invitation_card_price {
  color: #21902F;
}

.invitation_card_price h5 {
  padding: 2px;
  display: flex;
  justify-content: center;
}

.user_card {
  width: 120px;
  padding-top: 8px;
  border-radius: 10px;
  margin: 20px;
  transition: .2s;
  max-height: 25rem;
}

.user_card h5,
.user_card h6 {
  margin: 2px auto;
}

.user_card .user_name {
  width: 100%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
}

.user_card .display_name,
.user_card .mobile {
  color: #888;
}

.ant-modal-root .ant-modal-mask {
  backdrop-filter: blur(7.5px);
  background-color: rgba(68, 68, 68, 0.51);
}

.invitation_view {
  width: 100% !important;
}

.invitation_section {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 99%;
}

.template_details_container {
  display: flex;
  width: 600px;
  align-items: flex-start;
  gap: 40px;
}

.inv_card_left_section {
  max-width: 46%;
}

.inv_card_right_section {
  width: 52%;
}

.inv_img_container {
  background: #F1F1F1;
  height: 333px;
  width: 180px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 61.667px 16.667px 0px rgba(0, 0, 0, 0.00), 0px 40px 16.667px 0px rgba(0, 0, 0, 0.01), 0px 21.667px 13.333px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.09), 0px 1.667px 5px 0px rgba(0, 0, 0, 0.10);
}

.vertical-line {
  border-left: 1px dashed #000;
  height: 724px;
  margin: 0 10px;
}

.invitation_card:hover {
  transform: translate(0, -10px);
  color: #fff;
  text-decoration: none;
}

.user_card:hover {
  transform: translate(0, -10px);
  text-decoration: none;
}

.user_card:hover .user_image_container {
  border-color: #005580;
}

.user_card:hover .user_name,
.user_card:hover .display_name,
.user_card:hover .mobile {
  color: #005580;
}

.invitation_card.selected {
  background-image: linear-gradient(90deg, #0097bd, #4881a7);
  color: #fff;
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  height: 70vh;
  margin-top: 20px;
  gap: 40px 80px;

}

.template_det_modal {
  width: max-content !important;

}

.modal-content {
  /* width: 50rem; */
  overflow-y: scroll;
}

.user-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  height: 64.5vh;
  overflow: scroll;

}

.templ_image_container {
  height: 140px;
  width: 80px;
  margin: 10px auto;
  background-color: #164373;
  border-radius: 10px;
}

.user_image_container {
  height: 80px;
  width: 80px;
  margin: 10px auto;
  background-color: #164373;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #CCC;
}

.templ_image {
  height: 140px;
  width: 80px;
}

.templ_title {
  width: 10.4vw;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user_image {
  height: 80px;
  width: 80px;
}

#user_search_box {
  width: 87%;
  margin-left: 2%;
  border: none;
  border-radius: 15px;
  color: #000;
  background: #e1e1e1;
  padding: 10px;
}

#user_search_box_1 {
  width: 100%;
  border: none;
  border-radius: 15px;
  color: #000;
  background: #e1e1e1;
  padding: 10px;
  margin-bottom: 15px;

}

#templates_search_box {
  width: 85%;
  margin-left: 2%;
  border: none;

  border-radius: 15px;
  color: #000;
  background: #e1e1e1;
  padding: 10px;
}

.templ_details {
  height: 143px;
  background-image: linear-gradient(90deg, #baccd2, #c9d0df);
  padding: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: .5s;
}

.templ_price {
  display: inline;
}

.edit_price,
a.submit_price {
  background-color: #ccc;
  padding: 10px;
  border-radius: 50%;
  position: relative;
  top: -3px;
  margin-left: 30px;
}

.invitation_card:hover .templ_details,
.invitation_card.selected .templ_details {
  background-image: linear-gradient(90deg, #0097bd, #4881a7);
}

.templ_pricepane {
  display: flex;
}

.templ_desc {
  display: block;
}

.catandfont {
  width: 75%;
}

.price {
  margin-top: 17px;
}

.non-editable-details {
  display: flex;
  flex-wrap: wrap;
  /* height: 86vh; */
  overflow: scroll;
  padding: 10px;
}

.templ_view_container {
  margin: 10px;
  padding: 10px;
  border-radius: 12px;
  width: 31%;
}

.templ_image_view {
  width: 140px;
  height: 235px;
}

.user_image_view {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.templ_view_details {
  padding: 0 10px 10px 10px;
  width: 45%;
}

#inv_preview_cnt {
  height: 76vh;
  overflow: scroll;
  width: 41%;
}

#user_preview_cnt {
  height: 85vh;
  width: 49%;
  overflow: scroll;
}


.user_preview_sectn {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.layer-btn {
  padding: 8px 15px;
  margin: 3px;
}

.floar-right-btn {
  float: right;
  position: relative;
  right: 30px;
}

.layer_head {
  display: block;
}

input#edit_price {
  width: 80px;
  font-size: 20px;
  padding: 2px;
  border: none;
  border-bottom: 1px solid #164373;
}

.layer_label_content {
  position: absolute;
  top: -5px;
  font-size: 12px;
  font-weight: bold;
}

.add_layer {
  border: 1px solid #222;
  display: none;
  padding: 10px 5px;
  border-radius: 12px;
  transition: .4s;
}

.add_layer.show_new {
  display: block;
}

.fields .col-md-12,
.fields .col-md-10,
.fields .col-md-8,
.fields .col-md-6,
.fields .col-md-3,
.fields .col-md-4,
.fields .col-md-5 {
  float: none !important;
  display: inline-block;
}

.no-margin {
  margin: 0 !important;
}

.line_1 {
  margin-top: 14px !important;
  border-color: #aaa !important;
}

.close-layer {
  background: #a00;
  color: #fff;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: -20px;
}

#close-layer_id {
  background: #a00;
  color: #fff;
  position: absolute;
  right: 10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-top: -20px;
  top: 0px;
}

ul.no-list {
  list-style: none;
  padding-left: 0;
}

.layer_list_item {
  background-color: #ccc;
  border-radius: 12px;
  padding: 12px 15px;
  margin: 5px;
  height: auto;
  transition: .4s;
}

.boldtext {
  font-size: 12px;
  margin-left: 10px;
}

.layer_color_ribbon {
  position: absolute;
  right: 20px;
  height: 88px;
  width: 90px;
  margin-top: -75px;
  border: 6px solid #ccc;
}

.editing .layer_params,
.editing .layer_color_ribbon {
  display: none;
}

.edit_fields {
  display: none;
}

.edit_layer {
  text-decoration: none;
  color: #444;
}

a.edit_layer:hover {
  text-decoration: none;
  color: #444 !important;
}

.editing .edit_fields {
  padding-top: 20px;
  display: inline list-item;
}

.stop_editing {
  background-color: #ae2929;
  color: #eee;
  top: -40px;
  display: none;
}

.editing .start_editing {
  display: none;
}

.start_editing {
  background-color: #3e8e41;
  color: #222;
  top: -5px;
  right: -5px;

}

.editing .stop_editing {
  display: block;
}

.stop_editing,
.start_editing {
  padding: 5px 8px;
  float: right;
  position: relative;
  border-radius: 50%;
  z-index: 2;
}

.w-25,
.w-30,
.w-40,
.w-50 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 33.33%;
}

.w-40 {
  width: 41.67%;
}

.preview_btn {
  position: relative;
  margin-left: 12px;
  font-size: 20px;
  padding: 5px 8px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
}

.preview_layer {
  position: absolute;
  background-color: transparent;
  /* max-width: 240px; */
  flex-wrap: wrap;
}

/*--------------------------------------------------
Toggelswitch css
--------------------------------------------------*/
.toggle_container {
  text-align: left;
  font-size: 18px;
  display: flex;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  text-align: left;
  top: 15px;
}

.checkbox {
  display: none;
}

.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  padding: 0;
  text-align: left;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 21px;
  padding: 0;
  line-height: 22px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "YES";
  padding-left: 10px;
  background-color: #060;
  color: #fff;
}

.inner:after {
  content: "NO";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}

.switch {
  display: block;
  width: 12px;
  height: 12px;
  margin: 4px 5px 0 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 35px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label .inner {
  margin-left: 0;
}

.checkbox:checked+.label .switch {
  right: 0px;
}

.label_content {
  font-size: 18px;
  display: inline;
  position: relative;
  top: 6px;
  margin-right: 2px;
}

/*  add respose form */
.addresform {
  display: none;
  width: 35%;
  height: 30%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 4px #888484;
  margin-left: 30%;
  background: cadetblue;
  padding: 50px 20px;
  flex-direction: column;
}

.add_res_form {
  border: 1px solid #000;
  border-radius: 5px;
  padding: 20px;
}

#addres {
  float: inline-start;
}

#close_res_form {
  float: inline-end;
}

#res_name {
  width: 435px;
  height: 85px;
}

/*  profile card */

.profile_card {
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
  /* line-height: 70px; */
  padding-left: 25px;
  margin-bottom: 30px;
}

.profile_card_details {
  padding-left: 15px;
  color: #fff;
}

.profile_card_icon {
  padding-top: 1rem;
  height: 10rem;
  width: 10rem;
  padding-left: 1rem;
  border-radius: 100%;
  background-image: linear-gradient(to right, #ffffff75, #ffffff75);
  margin-top: 1rem;
}

.edit_card_user {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

.user_det_edit_form {
  /* height: 100vh; */
  background: #eee;
  width: 100%;
  padding: 15px;
}

.updateuserform {
  display: none;
  width: 90%;
  height: 50vh;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 4px #888484;
  margin-left: 5%;
  margin-top: 50px;
  background: #ddd;
  padding: 15px 20px;
  flex-direction: column;
}

.updateuser_form {
  padding: 10px;
}

.updateuser_form_input {
  float: right;
  margin-left: 10vw;
}

/* New 10-10-2023 */
#maritalstatus.updateuser_form_input {
  float: none !important;
  margin-left: 20px !important;
  width: 75%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#district.updateuser_form_input {
  float: none !important;
  margin-left: 60px !important;
  width: 75%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#state.updateuser_form_input {
  float: none !important;
  margin-left: 75px !important;
  width: 74.5%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#country.updateuser_form_input {
  float: none !important;
  margin-left: 55px !important;
  width: 75%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#status.updateuser_form_input {
  float: none !important;
  margin-left: 65px !important;
  width: 75%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#gender.updateuser_form_input {
  float: none !important;
  margin-left: 60px !important;
  width: 75%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}

#frnd_gen_change {
  width: 78%;
  background: #fff;
  border: 1px solid;
  border-radius: 5px;
  height: 30px;
}


/* END */

.showtree {
  display: flex;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;
}

.showfriends {
  display: none;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;

}

.showcircles {
  display: none;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;

}

.showinvitations {
  display: none;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;

}

.showevents {
  display: none;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;

}

.showtemplates {
  display: none;
  height: 50rem;
  border-radius: 5px;
  margin-top: 50px;
  padding: 10px 20px 50px 20px;
  flex-direction: column;
  overflow: scroll;

}

/* Users preview section */
#user_btns {
  display: flex;
  justify-content: space-between;
}

/* Buttons */
#showfamilytree {
  border: none;
  background: #eee;

}

#showfamilytree:hover {
  background: #eee;
}

#showfamilytree:active {
  background: olive;
}

#showfamilytree:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showfriendsbtn {
  border: none;
  background: #eee;

}

#showfriendsbtn:hover {
  background: #eee;
}

#showfriendsbtn:active {
  background: olive;
}

#showfriendsbtn:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showeventsbtn {
  border: none;
  background: #eee;

}

#showeventsbtn:hover {
  background: #eee;
}

#showeventsbtn:active {
  background: olive;
}

#showeventsbtn:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showinvitations {
  border: none;
  background: #eee;
}

#showinvitations:hover {
  background: #eee;
}

#showinvitations:active {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showinvitations:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showtemplates {
  border: none;
  background: #eee;

}

#showtemplates:hover {
  background: #eee;
}

#showtemplates:hover {
  background: #eee;
}

#showtemplates:active {
  background: olive;
}

#showtemplates:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showusercircles {
  border: none;
  background: #eee;

}

#showusercircles:hover {
  background: #eee;
}

#showusercircles:active {
  background: olive;
}

#showusercircles:focus {
  background: olive;
  background: linear-gradient(180deg, #eee 90%, #00FFFF 10%);
  color: #00FFFF;
}

#showusereditfrom {
  background: #026fbf;
  border: none;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  justify-content: center;
}

#close_edit_form {
  float: right;
}

/* user forms */
#closeform2 {
  width: 100%;
}

#family_tree_sec,
#friends_sec,
#circles_sec,
#invitations_sec,
#templates_sec,
#events_sec {
  padding: 20px;
  background: #d9eaf6;
  border-radius: 10px;
  width: 100%;
}

/* Tables */
/* user family members table */
#user_familymembers_table,
#user_friends_table,
#user_circles_table,
#user_events_table,
#user_templates_table,
#user_invitations_table {
  width: 100%;
  height: 10px;
  overflow: scroll;
}

.line-seperator {
  margin: auto 30px;
  border-bottom: 1px solid #ddd;
  width: 90%;
}

/* #showfamilytree{
  border: none;
} */
/* -------------Popup Form CSS------------- */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.popup-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
}

.popup-inner h2 {
  margin-top: 0;
}

.popup-inner label {
  display: block;
  margin-bottom: 5px;
}

.popup-inner input,
.popup-inner textarea,
.popup-inner select {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.faq_list {
  list-style: none;
  width: 100%;
}

.faq_item {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  margin: 5px auto;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 3px #ccc;
}

.faq_item i {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  color: #aaa;
}

.faq_item_div {
  height: 3.5rem;
  overflow: hidden;
  transition: all ease-in .2s;
}

.faq_item_div.expand_faq {
  min-height: 13rem;
  height: auto;
}

.faq_question {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.order-card {
  color: #fff;
  margin: 10px;
  background-color: #284672;
}

.products_cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .card_description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
} */

.expanded {
  white-space: normal;
  max-width: none;
}

.card-container {
  display: flex;
  justify-content: space-between;
  /* background: linear-gradient(to right, #ff8a00, #e52e71); */
  /* Adjust the colors as needed */
  padding: 10px;
}

.discount-tag-wrapper {
  display: flex;
}


.discount-bagde-flag-wrap {
  position: absolute;
  top: 20px;
  left: -12px;
}

.discount-bagde-flag-wrap::before {
  content: "";
  position: absolute;
  top: 35px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 12px 0;
  border-color: transparent #bc8246 transparent transparent;
  margin: 0px 0px 0px 0px;
}

/* .badge-area-2 {
  width: 500px;
  height: 500px;
  margin: 0 auto;
  background: #666;
  position: relative;
} */

.badge-product-sale {
  display: table;
  position: absolute;
  text-align: center;
  top: 37.5px;
  right: -29px;
  min-width: 100px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.badge-product-sale span {
  position: relative;
  background: #bc8246;
  color: #fff;
  float: left;
  font-size: 10px;
  font-weight: 400;
  height: 25px;
  line-height: 28px;
  padding: 0 5px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0px;
  min-width: 35px;
  border-radius: 0;
  min-height: auto;
  border: 0;
}

.badge-product-sale span.percent {
  background: #201f1f;
}

.badge-product-sale span::before {
  border: 5px solid #bc8246;
  border-color: #bc8246 transparent transparent #bc8246;
  border-width: 9px 5px;
  position: absolute;
  right: -9px;
  top: 0;
  content: "";
  z-index: 1;
}

.badge-product-sale span::after {
  border: 5px solid #bc8246;
  border-color: transparent transparent #bc8246 #bc8246;
  border-width: 10px 5px;
  position: absolute;
  right: -9px;
  bottom: 0;
  content: "";
}

.badge-product-sale span.percent::before {
  border: 5px solid #201f1f;
  border-color: #201f1f transparent transparent #201f1f;
  border-width: 9px 5px;
}

.badge-product-sale span.percent::after {
  border: 5px solid #201f1f;
  border-color: transparent transparent #201f1f #201f1f;
  border-width: 10px 5px;
}

.card_discount {
  text-transform: capitalize;
  color: #ffffff;
  background: #000;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  padding: 10px 15px;
  display: block;
  text-decoration: none;
}


.flip-card {
  background-color: transparent;
  width: 270px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.faq_question:hover {
  overflow: visible;
}

.faq_cat {
  color: #888;
  font-style: italic;
  font-size: 12px;
}

.popup-inner button[type='submit'] {
  background-color: #0077cc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.popup-inner button[type='submit']:hover {
  background-color: #005580;
}

.close-btn {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #ccc;
}

.addreleasenotespopupform {
  display: flex;
  justify-content: space-between;
}

.addFAQspopupform {
  display: flex;
  justify-content: space-between;
}

.addproductslkppopupform {
  display: flex;
  justify-content: space-between;
}

#message {
  /* width: 460px; */
  max-width: 460px;
}

/* ---------------Edit App_Notes Form-------------- */
#edit_release_notes {
  border-radius: 5px;
  padding: 5px;
  border: none;
  background: #b0d2eb;
}

.edit_app_notes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.products_stats_form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_app_notes-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  position: relative;

}



.products_stats_form-inner {
  color: #000;
  background-color: #cecece;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
}

/* new^ */
#edit_app_notes-inner_id {
  height: 50rem;
  overflow: scroll;
}

.edit_app_notes-inner h2 {
  margin-top: 0;
}

.edit_app_notes-inner label {
  display: block;
  margin-bottom: 5px;
}

.edit_app_notes-inner input,
.edit_app_notes-inner textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.edit_app_notes-inner button[type='submit'] {
  background-color: #0077cc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.edit_app_notes-inner button[type='submit']:hover {
  background-color: #005580;
}

.close-btn {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #ccc;
}

/* Faq's edit form css */
#update_FAQs_form_btn {
  height: 40px;
  width: 90px;
  /* float: right; */
  display: inline-block;
  padding: 10px 20px;
  /* margin: 0px 0px 10px 0px; */
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

#update_FAQs_form_btn:hover {
  background-color: #195c8a;
}

#update_FAQs_form_btn:active {
  transform: translateY(2px);
  background-color: #3498db;
}

#FAQs_edit_form_close_btn {
  background: #cecece;
  border: 0px;
  border-radius: 5px;
  height: 40px;
  width: 90px;
  color: #000;
}

#FAQs_l_btn {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;

}

#FAQs_l_btn_h3 {
  margin-left: 40px;
}

/* -----------Add Updates------------ */
/* .table_container{
  display: inline-flex;
flex-direction: row-reverse;
} */
/* buttons */
#add_release_notes_btn {
  float: right;
  background: #ffe180;
  color: #000;
  border-radius: 10px;
  padding: 12px;
  border: none;
}

#add_products_lkp_btn {
  float: right;
  background: #284672;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

#edit_product_lkp_btn {
  background: #284672;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

.edit_products_lkp,
.edit_products {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.edit_FAQs {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

#add_update_faqs {
  display: inline-block;
  padding: 10px 20px;
  /* margin: 0px 0px 10px 0px; */
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

#add_update_faqs {
  float: right;
}


#add_update_faqs:hover {
  background-color: #195c8a;
}

#add_update_faqs:active {
  transform: translateY(2px);
  background-color: #3498db;
}

#edit_FAQs_btn {
  display: inline-block;
  padding: 5px 15px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

#edit_FAQs_btn:hover {
  background-color: #195c8a;
}

#edit_FAQs_btn:active {
  transform: translateY(2px);
  background-color: #3498db;
}

.edit_products_lkp-inner,
.edit_products-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
}

.edit_FAQs-inner {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
}

/* Landing Page css */

.login_form {
  display: inline-block;
  background: #fff;
  width: 100%;
  padding: 100px;
  border-radius: 1px solid;
  border: 1px solid #fff;
  border-radius: 20px;
  margin-top: 50%;
  margin-left: 50%;
}

.l_form_title {
  margin-left: 35%;
}

.brand_container {
  display: flex;
}

/* Delete Users Page */
#btn_delete_user {
  background: #aaaaaa !important;
  border: 1px solid #ad3d4b;
}

#btn_delete_user:hover {
  background: #ad3d4b !important;
  color: #FFF;
}

/* #landing_img{
  margin-left: 30%;
  margin-top: -43%;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 10px;
} */

/* media quireis */

@media (max-width: 1200px) {
  .full_page {
    font-size: 80%;
  }
}

@media (max-width: 991px) {
  .side-nav {
    max-width: 20%;
  }
}

@media (max-width: 768px) {
  .full_page {
    font-size: 85%;
  }

  .side-nav.shrink {
    width: 0 !important;
  }

  .page-content.shrink {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .counttab {
    width: 30%;
  }

  .container2 {
    width: 100%;
  }

  .content {
    padding: 8px;
  }

  .modal-dialog {
    margin: 10px 10px;
  }

  .template_det_modal {
    width: auto !important;
    overflow: scroll;
  }
}

@media (max-width: 400px) {}

.user_edit_form {
  display: none
}

/* Responsive for 412 * 915 pixels */

@media only screen and (max-width: 915px) {

  /* Tags */
  h3 {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 5px;
  }

  /* Tags */

  .header {
    height: 8vh;
    padding-right: 0px;
  }


  #sidenav_menu_btn1 {
    display: none;
  }

  .header_elements {
    height: 6rem;
    width: 45%;

  }

  .header_profile_card {
    height: 6vh;
  }

  .header-user-details {
    width: 85px;
    height: 6vh;

  }

  #h_u_name {
    width: 85px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #h_u_email {
    width: 85px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .login_form {
    padding: 35px;
    margin-left: 0px;
  }

  /*.margin-auto{
    margin-top: 0%;
    margin-bottom: auto;
    margin-left: 5%;
    margin-right: auto;
    width: 90vw;
    padding: 0px;
    border-radius: 1.2rem;
    box-shadow: 2px 2px 12px #222;
    background-color: #eee;
    color: #444;
  
}
.login_form{
  width: 90vw;
}
.landing_container{
padding-top: 50%;
}
.brand-container{
  margin-top: -70px;
  margin-left: 120px;
}*/
  .login_input {
    width: 20rem;
  }
}

/*  */
/* @media only screen and (max-width: 760px) {
  .login_input{
    width: 15rem;
  }
  .brand-container{
    margin-left: 100px;
  }
} */



/* ---------------------------------------- Landing Page CSS ------------------------------------------------ */

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 25px;
  margin-top: 15vh;
}

.login-container h4 {
  text-align: center;
  margin-bottom: 30px;
}

.login-container .form-control {
  margin-bottom: 20px;
}

.login-container .btn {
  width: 100%;
}

.landingpage-container {
  height: 100vh;
  background: linear-gradient(315deg, #026fbf 10%, #63b0db 75%);
  padding: 10px 10px 10px 10px;
}

.brand {
  margin-left: 20%;
  margin-right: 20%;
}

.input-password,
.input-username {
  background: #d7d7d7;
}

.login_form_container {
  margin-left: 20%;
}

.login_btn {
  width: 78% !important;
  padding: 10px;
  margin: 0px;
  color: #eee;
  z-index: 1;
}

/* .login_btn:hover{
  color: #222;
} */
/* .btn::before{
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #3ce;
  z-index: -1;
  transition: 0.5s;
} */
.login_btn::before {
  top: 0;
  border-radius: 0 0 50% 50%;
}

.login_btn:hover::before {
  height: 180%;
}

body {
  text-align: left;
}

/* CSS from 29-09-2023 */
#list_items_user_reports {
  background: #cecece;
  width: 70%;
  border: none;
  border-radius: 10px;
  padding: 10px;
}

/* css:- 31-10-2023 */
.profile_details_card {
  padding: 30px 200px 30px 200px;
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
}

#right_col_height {
  height: 100vh;
}

.profile_details_container {
  /* padding-left: 40px; */
  width: 50vw;
}

.profile_password_container {
  width: 20vw;
  background: #fff;
  padding: 20px 0px 40px 40px;
  height: 30vh;
  width: 25vw;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px #cecece;

}

.profile_activity_card {
  width: 20vw;
  background: #fff;
  padding: 20px 0px 40px 40px;
  height: 30vh;
  width: 25vw;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px #cecece;

}

.profile_details_container_input_fields {
  width: 20vw;
  height: 40px;
  border: 1px solid #cecece;
  border-radius: 10px;
  padding: 10px;
  font-size: 20px;
  font-weight: 100;
}


.pro_pass_flex_container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.profile_details_btn {
  height: 40px;
  border: 1px solid #cecece;
  border-radius: 5px;
  background-color: #2a5eb0;
  color: #fff;
  transition: all 0.25s;
}

.profile_details_btn:hover {
  transition: all 0.25s;
  background-color: #0c2751;
}

.not-allowed-cursor {
  cursor: not-allowed;
}

.invitations_pagination_btns {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.invitations_pagination_btns:hover {
  background-color: #0056b3;
}

/* Faded effect when the button is disabled */
.invitations_pagination_btns:disabled {
  background-color: #cccccc;
  /* Light gray background */
  color: #666666;
  /* Dark gray text */
  cursor: not-allowed;
  /* Change cursor to indicate disabled state */
  opacity: 0.5;
  /* Reduce opacity for the fade effect */
}