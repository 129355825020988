/* .user-pagination {
    margin-left: 20px;
}

.users_pageination_btns {
    border-radius: 50%;
    padding: 5px 10px;
    border: 1px solid #444;
    cursor: pointer;
}

.users_pageination_btns:hover {
    background-color: #284672;
    color: #eee;
    border: 1px solid #444;
}

.users_pageination_btns:disabled {
    background-color: #eee;
    color: #ccc;
    border: 1px solid #ccc;
}

.user-pagination span {
    margin: auto 15px;
} */
.users_pagination_btns {
    padding: 8px 12px;
    margin: 2px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #fff;
    color: #000;
}

.users_pagination_btns.faded {
    opacity: 0.5;
    cursor: not-allowed;
}

.users_pagination_btns.active {
    background-color: #007bff;
    color: white;
}

.users_pagination_btns:hover:not(.faded) {
    background-color: #007bff;
    color: white;
}

.go-to-page-input {
    width: 50px;
    margin-left: 10px;
    padding: 7px;
}

.go-to-page-btn {
    padding: 7px 10px;
    margin-left: 5px;
    cursor: pointer;
}