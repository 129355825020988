.survey_container_header {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.add_survey_btn {
    background: #026FBF;
    color: #fff;
    border: none;
    text-align: center;
    border-radius: 10px;
    padding: 7px 25px 5px 25px;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
}

.added_surveys_container_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: 100%;
}

.added_surveys_container_content>div {
    flex-grow: 1;
    margin-right: 10px;
}

.survey_info {
    margin: 0;
}

.added_surveys_container_content>div>div {
    font-size: 15px;
    color: #4B4B4B;
    font-weight: 200;
}

.added_surveys_container_content_icon_main {
    float: inline-end;
}

.added_surveys_container_content_text_title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.added_surveys_container_content_text_icon {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 10px;
}

.added_surveys_container_content_text_counts {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.added_surveys_container_content_text_icon_stats {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
}

.added_surveys_container_content {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.added_surveys_container_content:hover {
    background-color: #A3B9C9;
    cursor: pointer;
    color: #fff;
}

/* .survey_page_flex_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
} */

.added_surveys_container {
    width: 100%;
    height: 95vh;
    overflow-y: scroll;
}

.survey_page_content_add_question_container {
    width: 70%;
}

.suvey_details_tabs_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.survey_det_container_tabs button.active-tab {
    background-color: #026FBF;
    color: white;
    /* border: none; */
}

.survey_det_container_tabs button {
    border: none;
    font-weight: 500;
    font-size: medium;
    padding: 10px;
}

#b1 {
    margin-right: 20px;
}

.add_questions-button {
    background: #026FBF;
    border: none;
    font-weight: 500;
    font-size: medium;
    color: #fff;
    padding: 10px;
}

.add_questions_survey_details_header_flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Questions {
    overflow-y: scroll;
}

/* toogle switch */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.survey_question_and_type_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.question_response_type {
    width: 100%;
    border: 1px solid #cecece;
    height: 30px;
}

.add_question_text_inputfield {
    width: 100%;
    border: 1px solid #cecece;
    height: 30px;
    background: #f1f1f1;
}

.add_question_lable {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
}

input[type="radio"] {
    margin-right: 8px;
}

.add_question-inner label {
    margin-bottom: 5px;
}


.add_question-inner input,
.add_question-inner textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.add_question-inner button[type='submit'] {
    background-color: #0077cc;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    cursor: pointer;
}

.add_question-inner button[type='submit']:hover {
    background-color: #005580;
}

.add_question-inner {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    position: relative;

}

.question_edit_buttons {
    color: #026FBF;
}

.survey_questions_container {
    height: 75vh;
    overflow-y: scroll;
}

/* surveys css */
.questions_container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    background: #dddddd;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.question_container_question_options {
    display: flex;
}

.questions_container_buttons {
    border: none;
    background-color: #dddddd;
}

.questions_container_buttons_container {
    margin-right: 0px;
}

.questions_container_bars_icon {
    margin-right: 20px;
}

.Question_container_block {
    flex: 1;
}