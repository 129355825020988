.non-editable-details {
    display: flex;
    flex-wrap: wrap;
    overflow: scroll;
    padding: 10px;
}

.templ_view_container {
    margin: 10px;
    padding: 10px;
    border-radius: 12px;
    width: 31%;
}

.templ_image_view {
    width: 140px;
    height: 235px;
}

.toggle_container {
    text-align: left;
    font-size: 18px;
    display: flex;
}

.label_content {
    font-size: 18px;
    display: inline;
    position: relative;
    top: 6px;
    margin-right: 2px;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    text-align: left;
    top: 15px;
}

.templ_view_details {
    padding: 0 10px 10px 10px;
    width: 65%;
    overflow-y: scroll;
}

.templ_price {
    display: inline;
}

.edit_price,
a.submit_price {
    background-color: #ccc;
    padding: 10px;
    border-radius: 50%;
    position: relative;
    top: -3px;
    margin-left: 30px;
}

input#edit_price {
    width: 80px;
    font-size: 20px;
    padding: 2px;
    border: none;
    border-bottom: 1px solid #164373;
}

.layer_head {
    display: block;
}

.layer-btn {
    padding: 8px 15px;
    margin: 3px;
}

ul.no-list {
    list-style: none;
    padding-left: 0;
}

.layer_list_item {
    background-color: #ccc;
    border-radius: 12px;
    padding: 12px 15px;
    margin: 5px;
    height: auto;
    transition: .4s;
}

.edit_layer {
    text-decoration: none;
    color: #444;
}

a.edit_layer:hover {
    text-decoration: none;
    color: #444 !important;
}

.stop_editing {
    background-color: #ae2929;
    color: #eee;
    top: -40px;
    display: none;
}

.editing .stop_editing {
    display: block;
}

.stop_editing,
.start_editing {
    padding: 5px 8px;
    float: right;
    position: relative;
    border-radius: 50%;
    z-index: 2;
}

.editing .start_editing {
    display: none;
}

.start_editing {
    background-color: #3e8e41;
    color: #222;
    top: -5px;
    right: -5px;

}

.layer_color_ribbon {
    position: absolute;
    right: 20px;
    height: 88px;
    width: 90px;
    margin-top: -75px;
    border: 6px solid #ccc;
}

.editing .layer_params,
.editing .layer_color_ribbon {
    display: none;
}

.edit_fields {
    display: none;
}

.editing .edit_fields {
    padding-top: 20px;
    display: inline list-item;
}

.form-field {
    margin-bottom: 20px
}

.special-input {
    padding: 10px 10px;
    width: 100%;
    font-size: 14px;
}

.special-lable {
    position: absolute;
    font-size: 20px;
    background-color: #fff;
    margin: 7px 0 0 14px;
    left: 14px;
    transition: .2s;
}

.special-input.hastext+.special-lable,
.special-input:focus+.special-lable {
    font-size: 12px;
    margin: -7px 0 0 8px;
    padding: 0 8px;
}

.special-input:focus+#special-lable_title {
    background: #cecece;
}

.special-input:focus+#special-lable_description {
    background: #cecece;
}

.special-input:focus+#special-lable_price {
    background: #cecece;
}

.special-input:focus+#special-lable_discount {
    background: #cecece;
}

.special-input:focus {
    border: 1px solid #467dff;

}

.validation-alert {
    color: #ae2929;
    display: none;
}

.validation-alert.invalid {
    display: block;
}

.w-25,
.w-30,
.w-40,
.w-50 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.layer_label_content {
    position: absolute;
    top: -5px;
    font-size: 12px;
    font-weight: bold;
}

.form-field {
    margin-bottom: 20px
}

.modal-mobile {
    width: 476px;
    height: 667px;
    border: 10px solid #444;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.modal-mobile .modal-body {
    padding: 0;
}

.preview_back {
    width: 456px;
    height: 647px;
    border-radius: 8px;

}

.preview_layer {
    position: absolute;
    background-color: transparent;
    flex-wrap: wrap;
}

.invitation-pagination {
    margin-left: 20px;
}

.invitations_pageination_btns {
    border-radius: 50%;
    padding: 5px 10px;
    border: 1px solid #444;
    cursor: pointer;
}

.invitations_pageination_btns:hover {
    background-color: #284672;
    color: #eee;
    border: 1px solid #444;
}

.invitations_pageination_btns:disabled {
    background-color: #eee;
    color: #ccc;
    border: 1px solid #ccc;
}

.invitation-pagination span {
    margin: auto 15px;
}

.template_tags {
    background: #cecece;
    border-radius: 5px;
    color: #000;
    width: max-content;
    padding: 8px;
    display: inline-flex;
    margin: 0px 0px 10px 10px;
    font-size: 10px;
}

.template_tags_container {
    width: 30vw;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.select_menu {
    background-color: #000;
}

.update_tags_btn {
    height: 38px;
    border: 1px solid;
    border-radius: 5px;
    margin-left: 10px;
    float: right;
}

.editables {
    width: 85vw
}

.preview_btn :hover {
    background-color: #ccc;
}

.add_tags_inner {
    background-color: #fff;
    position: fixed;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    position: relative;
    height: 30%;
    overflow: scroll;
}

.tag-grid {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.tag-grid li {
    display: inline-block;
    margin-right: 10px;
}

.tag_lables {
    background: #ccc;
    padding: 5px;
    line-height: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.add_question_label {
    max-height: 100px;
    overflow-y: scroll;
}

.editreleasenotespopupform {
    float: unset;
}

.add_tag_input_field {
    width: 100%;
    border: 1px solid;
    height: 30px;
    border-radius: 5px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
}

.invitations_pagination_btns {
    transition: opacity 0.3s ease;
    /* Adds a smooth transition */
}

.invitations_pagination_btns.disabled {
    opacity: 0.5;
    /* Faded appearance */
    pointer-events: none;
    /* Prevents clicking */
}